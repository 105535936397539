import React, { Component, ChangeEvent, FormEvent, useCallback } from "react";
import {
  Box,
  Flex,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Label
} from "primitives";
import { EditButton, SaveButton, CancelButton, InputField } from "components";
import { editSatelliteInstance } from "app/satellite/services";
import { SatelliteInstance } from "app/satellite/models";
import { SatelliteDefinition } from "app/satelliteDefinition/models";
import { FeedbackStatus } from "app/feedback/models";
import { setFeedback } from "app/feedback/actions";
import { store } from "app/store";
import { SelectSimple as Select } from "components";
import { setTLE } from "app/satelliteDefinition";
import { TLEDeleteContainer } from "app/satellite/containers/DeleteTLEContainer";
import { SuspenseQuery, Suspense } from "app/network";
import { connect } from "react-redux";
import { getTLEAction } from "app/constellation/actions";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import {
  GetTLEActionResult,
  GetTLEError,
  GetTLEResponse
} from "app/constellation/models";

interface SatelliteInstanceEditProps {
  getTLE: (satID: number) => Promise<GetTLEResponse | GetTLEError>;
  record: SatelliteInstance;
  onChange: () => void;
  satelliteDefinitions: SatelliteDefinition[];
}

interface SatelliteInstanceEditState {
  modalOpen: boolean;
  label: string;
  satelliteDefinitionId: number | string;
  tle?: string;
}

export class SatelliteInstanceEdit extends Component<
  SatelliteInstanceEditProps,
  SatelliteInstanceEditState
> {
  state = {
    modalOpen: false,
    label: this.props.record.label,
    satelliteDefinitionId:
      this.props.record.satelliteDefinitionSummary.satelliteDefinitionId,
    tle: this.props.record.tle
  };
  onChangeLabel(
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) {
    const { value } = event.currentTarget;
    this.setState({ label: value });
  }
  onChangeTLE(
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) {
    const { value } = event.currentTarget;
    this.setState({ tle: value });
  }

  onChangeSatelliteDefinitionID(event: ChangeEvent<HTMLSelectElement>) {
    const { value } = event.currentTarget;
    this.setState({ satelliteDefinitionId: value });
  }

  hideDialog = () => {
    const { record } = this.props;
    this.setState({ tle: record.tle });
    this.props.onChange();
    this.setState({ modalOpen: false });
  };

  openDialog = () => {
    this.setState({ modalOpen: true });
  };

  handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const { label, satelliteDefinitionId, tle } = this.state;
    const { record } = this.props;
    const satelliteInstance = {
      id: record.id,
      label,
      satelliteDefinitionId
    };
    setTLE(record.id, { satelliteID: record.id, tle });
    editSatelliteInstance(record, satelliteInstance)
      .then(() => {
        this.props.onChange();
        this.hideDialog();
      })
      .catch(() => {
        store.dispatch(setFeedback("Unauthorized", FeedbackStatus.ERROR));
        this.hideDialog();
      });
  };

  hideAfterDelete = (reload: { (): void; (): void }) => {
    reload();
    this.hideDialog();
  };

  query = async () => {
    const tle = await this.props.getTLE(this.props.record.id);
    if (tle != null && isTLEResponse(tle)) {
      this.setState({ tle: tle.tle });
    } else if (tle != null && isTLEError(tle)) {
      this.setState({ tle: "" });
    }
    return tle;
  };

  render() {
    const { record, satelliteDefinitions } = this.props;
    const { modalOpen, satelliteDefinitionId } = this.state;
    return (
      <Suspense>
        <SuspenseQuery query={this.query} errorFallback={true}>
          {({ reload }) => {
            return (
              <>
                <EditButton onClick={() => this.openDialog()} mr={1}>
                  Edit
                </EditButton>
                <Dialog
                  open={modalOpen}
                  maxWidth="md"
                  data-testid="SatelliteInstanceEdit"
                >
                  <DialogTitle>Edit</DialogTitle>
                  <DialogContent>
                    <form
                      onSubmit={(e) => this.handleSubmit(e)}
                      id="edit-satellite-instance"
                    >
                      <Box mb={2}>
                        <InputField
                          id="id"
                          required={true}
                          defaultValue={(record && record.id) || ""}
                          label="ID"
                          disabled
                        />
                      </Box>
                      <Box mb={2}>
                        <InputField
                          id="label"
                          required={true}
                          defaultValue={(record && record.label) || ""}
                          label="Label"
                          onChange={(e) => this.onChangeLabel(e)}
                        />
                      </Box>
                      <Box my={2} width="100%">
                        <Label>Satellite Definition</Label>
                        <Select
                          width="100%"
                          required={true}
                          name="satelliteDefinition"
                          value={satelliteDefinitionId}
                          onChange={(e) =>
                            this.onChangeSatelliteDefinitionID(e)
                          }
                        >
                          {satelliteDefinitions &&
                            satelliteDefinitions.map((satelliteDefinition) => (
                              <option
                                key={satelliteDefinition.id}
                                value={satelliteDefinition.id}
                              >
                                {satelliteDefinition.name}
                              </option>
                            ))}
                        </Select>
                        <Box mb={2}>
                          <InputField
                            id="tle"
                            multiline={true}
                            rows={3}
                            value={this.state.tle || ""}
                            label="TLE"
                            onChange={(
                              e:
                                | ChangeEvent<HTMLTextAreaElement>
                                | ChangeEvent<HTMLInputElement>
                            ) => this.onChangeTLE(e)}
                          />
                        </Box>
                      </Box>
                    </form>
                  </DialogContent>
                  <DialogActions>
                    <Flex
                      flexDirection="row"
                      justifyContent="flex-end"
                      alignItems="center"
                    >
                      <SaveButton
                        type="submit"
                        form="edit-satellite-instance"
                        mr={1}
                      >
                        Save
                      </SaveButton>
                      <TLEDeleteContainer
                        satID={record.id}
                        onChange={() => this.hideAfterDelete(reload)}
                      />
                      <CancelButton onClick={() => this.hideDialog()} mx={1}>
                        Cancel
                      </CancelButton>
                    </Flex>
                  </DialogActions>
                </Dialog>
              </>
            );
          }}
        </SuspenseQuery>
      </Suspense>
    );
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  getTLE: (satID: number) => dispatch(getTLEAction(satID))
});

export const SatelliteInstanceEditContainer = connect(
  null,
  mapDispatchToProps
)(SatelliteInstanceEdit);

function isTLEResponse(tle: any): tle is GetTLEResponse {
  return typeof tle === "object" && tle != null && typeof tle.tle === "string";
}

function isTLEError(tle: any): tle is GetTLEError {
  return (
    typeof tle === "object" && tle != null && typeof tle.status === "number"
  );
}
