import {
  FETCH_CONSTELLATION_START,
  FETCH_CONSTELLATION_SUCCESS,
  FETCH_CONSTELLATION_ERROR,
  SELECT_CONSTELLATION,
  CLEAR_CONSTELLATION,
  GET_TLE
} from "../models/constants";

const initialState = null;

export const constellationReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONSTELLATION_START:
    case CLEAR_CONSTELLATION:
    case FETCH_CONSTELLATION_ERROR:
      return initialState;
    case SELECT_CONSTELLATION:
    case FETCH_CONSTELLATION_SUCCESS:
      return {
        ...action.payload
      };
    case GET_TLE:
      if (action.payload && action.payload.satelliteID) {
        const sat = state.satelliteInstances.find(
          (sat) => sat["id"] === action.payload.satelliteID
        );
        sat.tle = action.payload.tle;
      } else {
        const sat = state.satelliteInstances.find(
          (sat) => sat["id"] === action.payload.satID
        );
        sat.tle = "";
      }
      return state;
    default:
      return state;
  }
};
