import React from "react";
import { SuspenseMutation } from "app/network";
import { Toggler } from "components";
import { DeleteButton, CancelButton } from "components";
import { connect } from "react-redux";
import { Flex, Dialog, DialogTitle, DialogActions } from "primitives";
import { deleteTLE } from "../services";
import { store } from "app/store";
import { setFeedback } from "app/feedback/actions";
import { FeedbackStatus } from "app/feedback/models";
import { DeleteTLEResponse } from "app/constellation/models";

interface TLEDeleteProps {
  satID?: any;
  onChange: () => void;
}

function handleDeleteTLEResponse(resp: DeleteTLEResponse) {
  if (
    resp.localDelete.deleted &&
    (resp.deleted.length === 0 ||
      resp.deleted.filter((p) => p.deleted).length === resp.deleted.length)
  ) {
    // Success if local delete and no external providers or all successfully deleted
    store.dispatch(
      setFeedback("Success", FeedbackStatus.SUCCESS, "TLE Deleted")
    );
  } else if (
    resp.localDelete.deleted &&
    // Warning if local delete and some external providers successfully deleted
    resp.deleted.filter((p) => p.deleted).length < resp.deleted.length
  ) {
    const failedProviders = resp.deleted
      .filter((p) => p.deleted)
      .map((p) => p.providerName);
    store.dispatch(
      setFeedback(
        "Success",
        FeedbackStatus.WARNING,
        `Failed to delete TLE on providers: ${failedProviders}`
      )
    );
  } else {
    // Error if not local delete
    store.dispatch(
      setFeedback("Error", FeedbackStatus.ERROR, "Unable to delete TLE")
    );
  }
}

export const TLEDelete = ({ satID, onChange, ...props }: TLEDeleteProps) => {
  const submit = async (onToggle: () => void) => {
    try {
      const deleteTLEResp = await deleteTLE(satID);
      console.log(deleteTLEResp);
      handleDeleteTLEResponse(deleteTLEResp);
      if (onChange) {
        onChange();
      }
      onToggle();
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  return (
    <Toggler>
      {([toggled, onToggle]) => (
        <>
          <DeleteButton onClick={onToggle}>Delete TLE</DeleteButton>
          <Dialog open={toggled} maxWidth="sm" data-testid="tle-delete">
            <SuspenseMutation>
              {({ loading, action }) => {
                return (
                  <>
                    <DialogTitle>
                      Delete TLE for Satellite: {satID}?
                    </DialogTitle>

                    <DialogActions>
                      <Flex alignItems="center">
                        <DeleteButton
                          onClick={() => action(() => submit(onToggle))}
                          mr={1}
                          disabled={loading}
                        >
                          Confirm Delete TLE
                        </DeleteButton>
                        <CancelButton onClick={onToggle} mr={1}>
                          Cancel
                        </CancelButton>
                      </Flex>
                    </DialogActions>
                  </>
                );
              }}
            </SuspenseMutation>
          </Dialog>
        </>
      )}
    </Toggler>
  );
};

const mapDispatchToProps = () => ({});

export const TLEDeleteContainer = connect(null, mapDispatchToProps)(TLEDelete);
